// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "bootstrap"
import 'jquery'

import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
// import * as ActiveStorage from "@rails/activestorage"
import "channels"

import "@fortawesome/fontawesome-free/js/all";

import '@fortawesome/fontawesome-free/scss/fontawesome';
import '@fortawesome/fontawesome-free/scss/solid';
import '@fortawesome/fontawesome-free/scss/regular';
import '@fortawesome/fontawesome-free/scss/brands';
import '@fortawesome/fontawesome-free/scss/v4-shims';

import 'et-line';

Rails.start()

// Turbolinks.start()
// ActiveStorage.start()

function recaptchaCallback() {
    $('#submitBtn').removeAttr('disabled');
};

window.recaptchaCallback = recaptchaCallback;
global.$ = require('jquery');
